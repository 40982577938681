import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import SanityBlockContent from "@sanity/block-content-to-react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Author from "./Author"
import { sanitySerializers, sanityClientConfig } from "../../utils/sanityConfig"

const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: ${props =>
    props.Sidebar ? `2px solid ${props.theme.colors.yellow}` : "unset"};
  margin-bottom: ${props => (props.Sidebar ? "2rem" : "0")};
  padding: 0.5rem;
  background: ${props => props.theme.colors.white};
  min-height: ${props => (props.Sidebar ? "unset" : "550px")};
  height: ${props => (props.Sidebar ? "unset" : "100%")};
  .card-image-holder {
    height: ${props => (props.Sidebar ? "200px" : "250px")};
    margin: -0.5rem;
    margin-bottom: 0.5rem;
    .card-image {
      height: 100%;
    }
  }
  .card-date-holder {
    margin-top: 2rem;
  }
  .card-tags {
    margin-right: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    padding: 0.5rem;
    background: ${props => props.theme.colors.purple};
    border-radius: 1rem;
    color: ${props => props.theme.colors.white};
    white-space: nowrap;
    margin-bottom: 0.5rem;
    display: inline-block;
  }
  .card-date {
    color: ${props => props.theme.colors.lightgray};
    font-size: 1.2rem;
    text-transform: uppercase;
  }
  .card-title {
    margin-top: 1rem;
    margin-bottom: 0;
    font-weight: bold;

    a {
      &:hover {
        text-decoration: none;
      }
    }
    h2 {
      &:hover {
        color: ${props => props.theme.colors.pink};
      }
      font-size: 2.4rem;
      margin-bottom: 0;
      color: ${props => props.theme.colors.purple};
    }
  }
  .card-summary p {
    margin-top: 2rem;
    color: ${props => props.theme.colors.black};
    font-size: 1.6rem;
    padding-right: 2rem;
  }
  .card-author {
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-self: start;
  }
`

const BlogItem = ({ item, sidebar }) => {
  const { i18n } = useTranslation()
  const language = i18n.language

  return (
    <StyledCard Sidebar={sidebar}>
      <div className="upper-part">
        <div className="card-image-holder">
          <Link to={`/${language}/blog/${item._rawSlug[language]?.current}`}>
            <GatsbyImage
              className="card-image"
              image={getImage(item.mainImage.asset)}
              alt="Blog image"
            />
          </Link>
        </div>
        <div className="card-date-holder">
          {item.tags?.map(tag =>
            tag?._rawTitle ? (
              <span
                className="card-tags"
                key={(Math.random() * Math.random()).toString()}
              >
                {tag?._rawTitle?.[language]}
              </span>
            ) : (
              ""
            )
          )}
          <span className="card-date d-block">{` ${
            item.publishedAt || "Nincs dátum"
          }`}</span>
        </div>
        <div className="card-title">
          <Link to={`/${language}/blog/${item._rawSlug[language]?.current}`}>
            <h2>{item._rawTitle[language]}</h2>
          </Link>
        </div>
        <div className="card-summary">
          <SanityBlockContent
            className="blog-blockcontent"
            serializers={sanitySerializers}
            blocks={item._rawSummary[language]}
            projectId={sanityClientConfig.sanity.projectId}
            dataset={sanityClientConfig.sanity.dataset}
            ignoreUnknownTypes={true}
            imageOptions={{ w: 1000, fit: "max" }}
          />
        </div>
      </div>
      <div className="card-author">
        <Author author={item.author} />
      </div>
    </StyledCard>
  )
}

BlogItem.propTypes = {
  item: PropTypes.object.isRequired,
  sidebar: PropTypes.bool,
}

BlogItem.defaultProps = {
  sidebar: false,
}

export default BlogItem
