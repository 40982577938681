import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import BlogItem from "../BlogItem"
import ShareButtons from "../SocialShareBar"
import styled from "styled-components"

const StyledSidebar = styled.div`
  .sub-header {
    margin: 0 auto 1.45rem;
    font-size: 1.4rem;
    text-transform: uppercase;
  }
`

const BlogSidebar = ({ activePost }) => {
  const { i18n, t } = useTranslation()
  const language = i18n.language
  const data = useStaticQuery(graphql`
    query {
      allSanityPost(
        sort: { fields: [highlighted, publishedAt], order: [DESC, DESC] }
      ) {
        edges {
          node {
            _id
            _rawSlug
            _rawTitle
            author {
              name
              image {
                asset {
                  gatsbyImageData(
                    layout: FIXED
                    height: 50
                    width: 50
                    fit: CROP
                  )
                }
              }
            }
            _rawBody
            _rawSummary
            tags {
              _rawTitle
            }
            mainImage {
              asset {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            publishedAt(formatString: "YYYY. MMMM D.", locale: "hu")
          }
        }
      }
    }
  `)
  const posts = data.allSanityPost.edges
    .filter(post => post.node._rawSlug[language]?.current !== activePost)
    .slice(0, 3)
  const _activePost = data.allSanityPost.edges.filter(
    post => post.node._rawSlug[language]?.current === activePost
  )[0]

  return (
    <StyledSidebar>
      <ShareButtons
        url={_activePost?.node._rawSlug[language]?.current}
        title={_activePost?.node._rawTitle[language]}
        description={_activePost?.node._rawSummary[language]}
        className="mt-5 mt-md-0 mb-5 mb-md-0"
        sidebar={true}
      />
      <div>
        <p className="sub-header">{t(`common:blog.morePosts`)}</p>
      </div>
      {posts?.map(p => (
        <BlogItem item={p.node} sidebar={true} key={p.node._id} />
      ))}
      <div
        className="fb-page"
        data-href="https://www.facebook.com/adomanybolt"
        data-tabs="timeline"
        data-width="315"
        data-height="350"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite="https://www.facebook.com/adomanybolt"
          className="fb-xfbml-parse-ignore"
        >
          <a href="https://www.facebook.com/adomanybolt">Cseriti Facebook</a>
        </blockquote>
      </div>
    </StyledSidebar>
  )
}

// getting the slug to filter active post
BlogSidebar.propTypes = {
  activePost: PropTypes.string.isRequired,
}

export default BlogSidebar
