import React from "react"
import PropTypes from "prop-types"
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { MediaQueries } from "../../utils/responsive"

const StyledShareButtons = styled.div`
  border-top: ${props =>
    props.sidebar ? `none` : `2px solid ${props.theme.colors.darkgray}`};
  max-width: 1000px;
  display: ${props => (props.sidebar ? "none" : "block")};

  @media ${MediaQueries.tablet} {
    display: block;
    height: ${props => (props.sidebar ? "172px" : "initial")};
  }
  @media ${MediaQueries.desktop} {
    display: block;
    height: ${props => (props.sidebar ? "225px" : "initial")};
    margin-bottom: ${props => (!props.sidebar ? "5rem" : 0)};
  }
  .text-holder {
    p {
      margin: 3rem auto 0;
      font-size: 1.4rem;
      text-transform: uppercase;
    }
  }
  .button-holder {
    padding-top: 1.5rem;
    padding-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .react-share__ShareButton {
      transition: all 0.2s ease-in-out !important;
      background-color: ${props => `${props.theme.colors.pink}!important`};
      width: 28%;
      margin-right: 1rem;
      padding: 1rem 1rem !important;
      border-radius: 10px;
      &:hover {
        background-color: ${props => `${props.theme.colors.purple}!important`};
      }
    }
  }
  svg {
    width: 20px;
    height: 20px;
    color: ${props => props.theme.colors.white};
    &:hover {
      color: ${props => props.theme.colors.lightgray};
    }
  }
`

const ShareButtons = ({ url, title, description, className, sidebar }) => {
  const { i18n, t } = useTranslation()
  const language = i18n.language

  // const baseUrl = `https://cseriti.hu/${language}`
  const baseUrl = `${process.env.GATSBY_BASE_URL}/${language}`
  // desctructure the blockContent, take the first paragraph
  const seoSummary = Array.isArray(description)
    ? description.map(i => i.children.map(c => c.text))[0][0]
    : description

  return (
    <StyledShareButtons className={className} sidebar={sidebar}>
      <div className="text-holder">
        <p>{t("common:blog.shareThis")}</p>
      </div>
      <div className="button-holder">
        <FacebookShareButton
          url={`${baseUrl}/blog/${url}`}
          quote={seoSummary}
          title={title}
        >
          <FaFacebook />
        </FacebookShareButton>
        <LinkedinShareButton
          url={`${baseUrl}/blog/${url}`}
          title={title}
          summary={seoSummary}
        >
          <FaLinkedin />
        </LinkedinShareButton>
        <TwitterShareButton url={`${baseUrl}/blog/${url}`} title={title}>
          <FaTwitter />
        </TwitterShareButton>
      </div>
    </StyledShareButtons>
  )
}

ShareButtons.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // description: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
  sidebar: PropTypes.bool,
  className: PropTypes.string,
}

ShareButtons.defaultProps = {
  className: "",
  sidebar: false,
  description: "Cseriti | Akár adsz, akár veszel, mindenképpen jót teszel.",
}

export default ShareButtons
