import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const StyledAuthor = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .author-picture {
    border-radius: 50%;
  }
  .text {
    padding-left: 2rem;
    font-weight: bold;
    span {
      font-size: 1.2rem;
    }
  }
`

const Author = ({ author }) => {
  return (
    <StyledAuthor>
      {author ? (
        <>
          {" "}
          <div className="picture">
            <GatsbyImage
              className="author-picture"
              image={getImage(author?.image?.asset)}
              alt={author?.name}
            />
          </div>
          <div className="text">
            <span>{author?.name}</span>
          </div>{" "}
        </>
      ) : (
        ""
      )}
    </StyledAuthor>
  )
}

// TODO add default placeholder image for authors

Author.propTypes = {
  author: PropTypes.object,
}

Author.defaultProps = {
  author: { name: "Admin" },
}

export default Author
