import React from "react"
import { graphql } from "gatsby"
import SanityBlockContent from "@sanity/block-content-to-react"
import Seo from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { sanitySerializers, sanityClientConfig } from "../utils/sanityConfig"
import { MediaQueries } from "../utils/responsive"
import Layout from "../components/layout"
import ShareButtons from "../components/SocialShareBar"
import Author from "../components/BlogItem/Author"
import BlogSidebar from "../components/BlogSidebar"
import HomeBackground from "../components/Visuals/homeBackground"

const StyledContainer = styled(Container)`
  @media ${MediaQueries.tablet} {
    background: ${props => props.theme.colors.white};
  }
  /* border-radius: 10px; */
  .fixed-height {
    height: 275px;
  }
  .blog-title {
    text-align: left;
    padding: 1.5rem;
    max-width: 90%;
    margin-bottom: 0;
    color: ${props => props.theme.colors.purple};
    @media ${MediaQueries.desktop} {
      font-size: 3.2rem;
      padding: 3rem 1.5rem 0;
      margin-bottom: 1.45rem;
    }
  }
  .blog-date {
    text-align: left;
    color: ${props => props.theme.colors.lightgray};
    padding-left: 1.5rem;
    font-size: 1.4rem;
    text-transform: uppercase;
  }
  .blog-tags {
    margin-right: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    padding: 0.5rem;
    background: ${props => props.theme.colors.purple};
    border-radius: 1rem;
    color: ${props => props.theme.colors.white};
    white-space: nowrap;
    margin-bottom: 0.5rem;
    display: inline-block;
  }
  .blog-summary p {
    color: ${props => props.theme.colors.darkgray};
    padding-left: 1.5rem;
    font-size: 1.6rem;
  }

  .blog-blockcontent {
    margin-left: 1.5rem;
    @media ${MediaQueries.mobile} {
      margin: 3rem auto;
    }
    p {
      color: ${props => props.theme.colors.darkgray};
      font-size: 1.6rem;
    }
    a {
      color: ${props => props.theme.colors.pink};
      font-size: 1.6rem;
      &:hover {
        color: ${props => props.theme.colors.darkgray};
        font-size: 1.6rem;
      }
    }
  }
  .blog-blockquote {
    border-left: ${props => `2px solid ${props.theme.colors.purple}`};
    padding-left: 2rem;
    font-style: italic;
    font-size: 1.6rem;
    @media ${MediaQueries.mobile} {
      max-width: 75%;
      font-size: 2.4rem;
    }
  }
  .blog-sidebar {
    background-color: #fafafa;
  }
`

const BlogPostPage = ({ data, path, alternateLinks }) => {
  const post = data.sanityPost

  const { i18n } = useTranslation()
  const language = i18n.language

  // provide a resized image (1200x630) for Facebook
  return (
    <Layout alternateLink={alternateLinks}>
      <Seo
        title={`${post._rawTitle[language]}`}
        description={
          post?._rawSummary?.[language] ||
          "Cseriti | Akár adsz, akár veszel, mindenképpen jót teszel"
        }
        image={getImage(post.mainImage?.asset)?.images.fallback.src}
        path={path}
      />

      {/* Facebook pixel script */}
      <Helmet>
        <script
          async
          defer
          crossorigin="anonymous"
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0&appId=1923256471276544&autoLogAppEvents=1"
          nonce="dIPn1kdy"
        ></script>
      </Helmet>
      <HomeBackground />
      <StyledContainer>
        <Container className="px-0">
          <section>
            <Row>
              <Col xs={12} md={{ size: 8 }} className="main-holder">
                <Row>
                  <Col
                    xs={12}
                    className="d-flex flex-wrap mb-4 w-100 fixed-height"
                  >
                    <div className="w-100">
                      <h1 className="blog-title">{post._rawTitle[language]}</h1>
                      <p className="blog-date">
                        {post.publishedAt || "Nincs dátum"}
                      </p>
                      <p className="pl-4">
                        {post.tags?.map(tag =>
                          tag?._rawTitle ? (
                            <span
                              className="blog-tags"
                              key={(Math.random() * Math.random()).toString()}
                            >
                              {tag?._rawTitle?.[language]}
                            </span>
                          ) : (
                            ""
                          )
                        )}
                      </p>
                      <SanityBlockContent
                        blocks={post._rawSummary[language]}
                        projectId={sanityClientConfig.sanity.projectId}
                        dataset={sanityClientConfig.sanity.dataset}
                        serializers={sanitySerializers}
                        renderContainerOnSingleChild={true}
                        className="blog-summary"
                      />
                    </div>
                    <div className="pl-4">
                      <Author author={post.author} />
                    </div>
                  </Col>
                  <Col xs={12} className="px-0 text-center mb-3 mb-lg-5">
                    <GatsbyImage
                      image={getImage(post.mainImage?.asset)}
                      className="main-image"
                      alt="Main image"
                    />
                  </Col>
                  <Col xs={12}>
                    <SanityBlockContent
                      className="blog-blockcontent"
                      serializers={sanitySerializers}
                      blocks={post._rawBody[language]}
                      projectId={sanityClientConfig.sanity.projectId}
                      dataset={sanityClientConfig.sanity.dataset}
                      ignoreUnknownTypes={true}
                      renderContainerOnSingleChild={true}
                      imageOptions={{ w: 1000, fit: "max" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="sidebar-holder">
                    <ShareButtons
                      url={post._rawSlug[language]?.current}
                      title={post._rawTitle[language]}
                      description={post._rawSummary[language] || "Cseriti.hu"}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={{ size: 4 }} className="blog-sidebar">
                <BlogSidebar activePost={post._rawSlug[language]?.current} />
              </Col>
            </Row>
          </section>
        </Container>
      </StyledContainer>
    </Layout>
  )
}

export default BlogPostPage

export const query = graphql`
  query Post($post: String) {
    sanityPost(_id: { eq: $post }) {
      _id
      _rawSlug
      _rawTitle
      author {
        name
        image {
          asset {
            gatsbyImageData(layout: FIXED, height: 50, width: 50, fit: CROP)
          }
        }
      }
      _rawBody
      _rawSummary
      tags {
        _rawTitle
      }
      mainImage {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 1200
            height: 630
          )
          url
        }
      }
      publishedAt(formatString: "YYYY. MMMM D.", locale: "hu")
    }
  }
`
